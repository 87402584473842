import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'registration'
const resourceStudent = 'student'

const GetActive = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}/active`)
const GetInactive = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}/inactive`)

const Create = data => SocialProjectManager.post(`${resourceStudent}/${data.studentId}/${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceStudent}/${data.studentId}/${resourceName}/${data.id}`, data)

const Delete = (studentId, id) => SocialProjectManager.delete(`${resourceStudent}/${studentId}/${resourceName}/${id}`)

const RegistrationRepository = {
  GetActive,
  GetInactive,
  Create,
  Update,
  Delete
}

export default RegistrationRepository