<template>
  <b-breadcrumb :items="breadcrumbItems" />
</template>

<script>
  export default {
    name: 'BreadCrumb',
    props: {
      breadcrumbItems: {
        type: Array,
        required: true,
      },
    },
    data: () => ({}),
  }
</script>

<style>
</style>