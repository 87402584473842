import Vue from 'vue'

// eslint-disable-next-line
export const replacePointToComma = (text) => {
  if (!text) return '-' 

  const textFormated = text.toString().replace(".",",")
  return textFormated
}

Vue.filter('replacePointToComma', replacePointToComma)