<template>
  <div>
    <b-pagination
      @input="getAll"
      v-model="pagination.index"
      :total-rows="pagination.count"
      :per-page="pagination.limit"
      class="mt-10"
      align="center"
    >
      <template v-slot:prev-text><span class="text-primary">Anterior</span></template>
      <template v-slot:next-text><span class="text-primary">Próxima</span></template>
      <template v-slot:ellipsis-text>
        <b-spinner small type="grow"></b-spinner>
        <b-spinner small type="grow"></b-spinner>
        <b-spinner small type="grow"></b-spinner>
      </template>
      <template v-slot:page="{ page, active }">
        <b v-if="active">{{ page }}</b>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    pagination: {
      type: Object,
      required: true
    },
    getAll: {
      type: Function,
      required: true
    }
  }
}
</script>
