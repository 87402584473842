import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import RegistrationRepository from '@/shared/http/repositories/socialProject/student-registration'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Mixin from '@/views/report/dashboard/report-dashboard-mixin'
import StudentsByImc from './components/studentByImc/StudentByImc.vue'
import GraphicPerStudent from './components/graphicPerStudent/GraphicPerStudent.vue'
import { replacePointToComma } from '@/shared/filters/ReplacePointToComma'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import StudentRepository from '../../../../shared/http/repositories/socialProject/student'
import ReportStudentRepository from '../../../../shared/http/repositories/socialProject/report-student'
import { IsSuccessCode } from '@/shared/utils/API'
import StudentMeasurementCriteria from '@/shared/models/criteria/studentmeasurementcriteria'
import StudentMeasurement from '@/shared/models/studentMeasurement'
import { loading, toast } from '@/shared/utils/UIHelper'
import MeasurementRepository from '@/shared/http/repositories/socialProject/student-measurement'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    StudentsByImc,
    GraphicPerStudent
  },

  mixins: [Mixin],

  data: () => ({
    criteria: new StudentMeasurementCriteria(),
    optionsTeachers: [],
    listLocalTraining: [],
    modalities: [],
    categories: [],
    students: [],
    selected: [],
    studentsSearched: [],
    measurements: [],
    registrations: [],
    studentInfo: {},
    showGraphicStudent: false,
    showDrawerStudentInfo: false,
    totalStudentsWithMeditions: 0,
    emptyTextGraphic: 'Clique em um elemento do gráfico para o preenchimento desta tabela',
    emptyTextCriteria: 'Faça uma pesquisa para o preenchimento desta tabela',
    emptyTextRegistration: 'Não há matrículas ativas',
    emptyText: 'Nenhuma Medição Cadastrada',
    fields: [
      {
        key: 'name',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'height',
        label: 'ALTURA',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'weight',
        label: 'PESO',
        class: 'middle-header text-center',
        formatter: replacePointToComma,
        sortable: true
      },
      {
        key: 'imc',
        label: 'IMC',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'measurementDate',
        label: 'ÚLTIMA MEDIÇÃO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      }
    ],
    fieldsDrawer: [
      {
        key: 'measurementDate',
        label: 'DATA DA MEDIÇÃO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'height',
        label: 'ALTURA',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'weight',
        label: 'PESO',
        class: 'right-header-border middle-header text-center',
        formatter: replacePointToComma
      }
    ],
    fieldsRegistration: [
      {
        key: 'dateStart',
        label: 'DATA DA MATRÍCULA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'middle-header-border text-center',
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center',
      },
      {
        key: 'className',
        label: 'CLASSE',
        class: 'middle-header text-center',
      },
      {
        key: 'categoryName',
        label: 'CATEGORIA',
        class: 'right-header-border text-center',
      },
    ],
    fieldsCriteria: [
      {
        key: 'name',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'height',
        label: 'ALTURA',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'weight',
        label: 'PESO',
        class: 'middle-header text-center',
        formatter: replacePointToComma,
        sortable: true
      },
      {
        key: 'imc',
        label: 'IMC',
        class: 'middle-header text-center',
        formatter: replacePointToComma
      },
      {
        key: 'imcCategoryName',
        label: 'CATEGORIA - IMC',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'dateMeasurement',
        label: 'ÚLTIMA MEDIÇÃO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      }
    ],
    paginationI: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            href: this.$router.resolve({ name: 'ReportStudentList' }).href
          },
          {
            text: 'Alunos por Medição'
          }
        ]
      }
    }
  },

  created() {
    this.loadModalityLocalTraining()
    this.getAllTeacher()
  },

  mounted(){
    this.getCountStudentsWithMeasures()
  },

  methods: {
    LoadStudents(students) {
      this.students = students
    },
    toggleAll() {
      if (this.selected.length) this.selected = []
      else this.selected = this.students.slice()
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    onRowSelected(student) {
      this.showGraphicStudent = true
      setTimeout(() => {
        this.$refs.GraphicPerStudent.loadChargeData(student.id)
      }, 1500);
    },
    onShowStudentInfo(student) {
      this.showDrawerStudentInfo = true
      this.studentInfo = student
      this.getAllMeasurement(this.studentInfo.id)
      this.getAllRegistrationByStudent(this.studentInfo.id)

      setTimeout(() => {
        this.$refs.GraphicPerStudentSearched.loadChargeData(student.id)
      }, 1500);
    },
    onCloseDrawer() {
      this.showDrawerStudentInfo = false
      this.studentInfo = {}
    },
    getAllMeasurement(studentId) {
      loading.push()

      if (studentId) {
        MeasurementRepository.GetAllMeasurementById(studentId)
          .then(res => {
            if (!IsSuccessCode(res)) return Promise.reject()

            this.measurements = res.data.data
            loading.pop()
            return Promise.resolve()
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao carregar medições', 'ERRO')
          })
      }
      loading.pop()
    },
    getCountStudentsWithMeasures() {
      StudentRepository.GetTotalWithMeasures()
        .then(response => {
          if(!IsSuccessCode){
            return Promise.reject()
          }

          this.totalStudentsWithMeditions = response.data.data.count
        })
        .catch(() => {
          console.log('Erro ao tentar obter o total de alunos com medições')
        })
    },
    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.criteria.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.criteria.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      this.categories = []
      if (this.criteria.modalityId) {
        CategoryRepository.GetAllByModality(this.criteria.modalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            this.$refs.snackBar.ShowError('Não foi possível carregar a lista de Categorias.')
          })
      }
    },

    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },
    getAllRegistrationByStudent(studentId) {
      loading.push()
      RegistrationRepository.GetActive(studentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.registrations = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos registrados', 'ERRO')
        })
    },
    getAll() {
      loading.push()
      ReportStudentRepository.GetStudentMeasurementSearched(this.criteria, this.paginationI)
        .then(res => {
          if (!IsSuccessCode(res)) {
            return Promise.reject()
          }

          this.studentsSearched = res.data.data.map(student =>
            new StudentMeasurement(student)
          )

          this.paginationI = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    onClearAllSearchTab() {
      this.criteria = new StudentMeasurementCriteria()
      this.paginationI.count = 1
      this.paginationI.index = 1
    }
  }
}
