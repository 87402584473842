export default class studentmeasurementcriteria {
  constructor({
    localTrainingId,
    modalityId,
    categoryId,
    teacherId,
    name,
  } = {}) {
    this.localTrainingId = localTrainingId,
    this.modalityId = modalityId,
    this.categoryId = categoryId,
    this.teacherId = teacherId,
    this.name = name
  }
}