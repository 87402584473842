import VueHighcharts from 'vue2-highcharts'
import { loading } from '@/shared/utils/UIHelper'
import ReportStudent from '@/shared/http/repositories/socialProject/report-student'
import { IsSuccessCode } from '@/shared/utils/API'
import { dateFormattedFilter, dateFormattedFilterCustom } from '@/shared/filters/ToDateFormatted'
import { dateFilter } from '@/shared/filters/ToDate'

export default {
  components: {
      VueHighcharts
  },
  data: () => ({
    options: {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
            minWidth: 600,
            scrollPositionX: 1
        }
      },
      title: {
          text: '',
          align: 'center'
      },
      subtitle: {
          text: '',
          align: 'center'
      },
      xAxis: {
        categories: [],
        type: 'datetime',
        labels: {
          formatter: function() {
            const date = dateFilter(this.value)
            return dateFormattedFilterCustom(date, 'MM/YYYY')
          }
        }
      },
      yAxis: {
          title: {
              text: 'IMC (Índice de Massa Corpórea)'
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands: [{ // Abaixo do Peso
              from: 0,
              to: 18.5,
              color: 'rgba(255, 50, 70, 0.1)',
              label: {
                  text: 'Abaixo do Peso',
                  style: {
                      color: '#606060'
                  }
              }
          }, { // Peso Normal
              from: 18.5,
              to: 24.9,
              color: 'rgba(60, 190, 66, 0.2)',
              label: {
                  text: 'Peso Normal',
                  style: {
                      color: '#606060'
                  }
              }
          }, { // SobrePeso
              from: 25,
              to: 29.9,
              color: 'rgba(255, 50, 70, 0.1)',
              label: {
                  text: 'Sobrepeso',
                  style: {
                      color: '#606060'
                  }
              }
          },
          { // Obesidade grau 1
              from: 30,
              to: 34.9,
              color: 'rgba(255, 50, 70, 0.2)',
              label: {
                  text: 'Obesidade grau 1',
                  style: {
                      color: '#606060'
                  }
              }
          }, { // Obesidade grau 2
              from: 35,
              to: 39.9,
              color: 'rgba(255, 50, 70, 0.3)',
              label: {
                  text: 'Obesidade grau 2',
                  style: {
                      color: '#606060'
                  }
              }
          }, { // Obesidade grau 3
              from: 40,
              to: 50,
              color: 'rgba(255, 50, 70, 0.4)',
              label: {
                  text: 'Obesidade grau 3',
                  style: {
                      color: '#606060'
                  }
              }
          }]
      },
      plotOptions: {
          spline: {
              lineWidth: 4,
              states: {
                  hover: {
                      lineWidth: 5
                  }
              },
              marker: {
                  enabled: false
              },
          }
      },
      series: [{
          name: 'IMC',
          data: []
      }],
      navigation: {
        menuItemStyle: {
            fontSize: '10px'
        }
      }  
    }
  }),
  methods: {
    loadChargeData(id) {
      loading.push()
      ReportStudent.GetImcHistoryById(id)
      .then(response => {

        if(!IsSuccessCode){
          return Promise.reject()
        }

        const student = response.data.data

        let datesMeasurements = []
        let imcValues = []

        student.measurements.forEach(measurement => {
          datesMeasurements.push(
            dateFormattedFilter(measurement.measurementDate)
          )
          imcValues.push(measurement.imc)
        })

        setTimeout(() => {
          this.$refs.lineCharts.chart.update({
            xAxis: {
              categories: datesMeasurements,
              text: null
            },
            series: [{
              name: 'IMC',
              data: imcValues
            }],
            title: {
              text: student.name,
              align: 'center'
            },
            subtitle: {
              text: student.currentImcCategory,
              align: 'center'
            }
          })

          loading.pop()
        }, 1300)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico "Histórico de Medições": \n ${e}`)
      })
    }
  }
}