import GuidUtils from '@/shared/utils/guid'

export default class StudentMeasurement {
    constructor({
      id,
      name,
      age,
      height,
      weight,
      imc,
      imcCategoryName,
      dateMeasurement
    } = {}) {
      this.id = id || GuidUtils.emptyGuid,
      this.name = name
      this.age = age
      this.height = height
      this.weight = weight
      this.imc = imc
      this.imcCategoryName = imcCategoryName
      this.dateMeasurement = dateMeasurement
    }
  }