import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'measurement'
const resourceStudent = 'student'

const GetAllMeasurementById = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)

const Create = (studentId, data) => SocialProjectManager.post(`${resourceStudent}/${studentId}/${resourceName}`, data)

const Update = (studentId, id, data) => SocialProjectManager.put(`${resourceStudent}/${studentId}/${resourceName}/${id}`, data)

const Delete = (studentId, id) => SocialProjectManager.delete(`${resourceStudent}/${studentId}/${resourceName}/${id}`)

const MeasurementRepository = {
  GetAllMeasurementById,
  Create,
  Update,
  Delete
}

export default MeasurementRepository