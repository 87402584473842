import Vue from 'vue';
import moment from 'moment';

// eslint-disable-next-line
export const dateFilter = (value) => {
  if (!value) return '-'

  moment.locale('pt-br')

  const d = moment(value, ["DD/MM/YYYY", moment.ISO_8601])

  if (d.isValid()) {
    return d.format('YYYY-MM-DD')
  }

  return 'Invalid Date';
}

Vue.filter('ToDate', dateFilter)
