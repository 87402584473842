import ReportStudent from '@/shared/http/repositories/socialProject/report-student'
import VueHighcharts from 'vue2-highcharts'
import Highcharts from 'highcharts'
import Mixin from '@/views/report/dashboard/report-dashboard-mixin'
import { loading } from '@/shared/utils/UIHelper'

export default {
  components: {
    VueHighcharts
  },
  mixins: { Mixin },
  data: function() {
    return {
      isChartLoading: false,
      options: {
        chart: {
          type: 'column',
          colorCount: 5
        },
        title: {
          text: '',
          align: 'left'
        },
        subtitle: {
          text: '',
          align: 'left'
        },
        xAxis: {
          categories: [],
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Número de Alunos',
            align: 'middle'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: this.LoadStudentsImc
            },
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            }
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
          shadow: true
        },
        credits: {
          enabled: false
        },
        series: []
      }
    }
  },

  mounted () {
    loading.push()
    this.getStudentsByImc()
  },

  methods: {
    LoadStudentsImc (e) {
      loading.push()
      ReportStudent.GetByStudentImc(e.point.id)
        .then(response => {
          response.data.result.map(x => x.imc = x.imc.toFixed(2))
          this.$emit('selectStudent', response.data.result)
          loading.pop()
        })
        .catch(e => {
          loading.pop()
          console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Medição": \n ${e}`)
        })
    },

    getStudentsByImc () {
      ReportStudent.GetStudentsByImc()
        .then(response => {
          let categoriesArr = []
          let dataArr = []
          let result = response.data.result

          result.forEach(item => {
            dataArr.push({'y': item.count, 'id': item.id})
            categoriesArr.push(item.name)
          })

          let chartData = [
            {
              name: 'Número de Alunos',
              data: dataArr,
              colorByPoint: true,
              colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
            }
          ]
          this.options.title.series = chartData
          this.options.xAxis.categories = categoriesArr

          setTimeout(() => {
            this.$refs.barChart.removeSeries(1)
            this.$refs.barChart.addSeries(chartData[0])

            this.$refs.barChart.chart.update({
              xAxis: {
                categories: categoriesArr,
                text: null
              }
            })
            loading.pop()
          }, 2000)
        })
        .catch(e => {
          loading.pop()
          console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Medição": \n ${e}`)
        })
    }
  }
}
